.resources-detail__container {
  padding-bottom: 80px;
  .resources-detail__main {
    height: 100%;
    width: 100%;
  }

  .resources-detail__header {
    background: #ffffff;
    margin: 0 -16px;
    padding: 0 16px;

    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .resources-detail__heading--sub {
    font-family: Catamaran, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    color: #222222;
  }

  .resources-detail__text--sub {
    font-family: Cabin, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #222222;
    word-break: break-word;
  }

  .resources-detail-content {
    display: flex;
    gap: 6px;

    &__item {
      border: 2px solid #ccc8c7;
      border-radius: 20px;
      padding: 6px 10px;

      .ant-btn {
        font-family: Lexend, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: #595d5c;
        padding: 0 !important;
        margin: 0;
        height: auto;
        min-height: 0;
      }
    }
  }

  .resources-detail-category {
    display: flex;
    gap: 6px;
    margin-top: 20px;

    &__item {
      background-color: #dddddd;
      border-radius: 20px;
      padding: 6px 10px;
      font-family: Lexend, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      &.sensitive {
        background-color: #ffe6df;
        color: #d13e32;
        text-decoration: underline;
      }
    }
  }

  .how-to-teach__text--container {
    padding: 25px 20px;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 10px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: Catamaran, sans-serif;
      font-style: normal;
    }

    p,
    li,
    a {
      font-family: Cabin, sans-serif;
      font-weight: 400;
    }
  }

  .text__link {
    border: none;
    color: #4b3ad3;
    font-family: Catamaran, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 15px;
    padding: 0;
  }

  .curriculum-list {
    ul {
      padding-left: 20px;
      li {
        font-family: Cabin, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .missions-list {
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      li {
        font-family: Lexend, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;

        img {
          width: 90%;
          height: auto;
          margin-bottom: 20px;
        }

        .mission-list__title,
        .mission-list__action {
          width: 90%;
        }
      }
    }
  }
}
