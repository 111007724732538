.labelStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #ffffff;
  line-height: 24px;
}

.inputFieldStyle {
  font-family: Cabin, sans-serif;
  font-weight: 800;
  font-size: 18px;
  font-style: normal;
  background: #ffffff;
  height: 52px;
  width: 586px;
  max-width: 100%;
  border: 2px solid #c2ceed;
}

.inputFieldSmallStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-style: normal;
  background: #ffffff;
  height: 50px;
  width: 277px;
  border: 2px solid #c2ceed;
}

.labelStyleCard {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-style: normal;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 950px) {
  body .inputFieldStyle {
    width: 320px;
  }
  body .inputFieldSmallStyle {
    width: 150px;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 951px) and (max-width: 1350px) {
  body .inputFieldStyle {
    width: 380px;
  }
  body .inputFieldSmallStyle {
    width: 180px;
  }
}
