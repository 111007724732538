.credit_badge__container {
  display: flex;
  align-items: center;
  border-radius: 50px;
  background-color: #ffffff;
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 14px 10px 10px;
  margin-right: 15px;

  img {
    margin-right: 5px;
  }
}
