.text-bubble {
    position: relative;
    background: #c2ceed;
    color: #000;
    text-align: left;
    width: 242px;
    min-height: 110px;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}
.text-bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #c2ceed transparent;
    border-width: 14px 14px 0;
    bottom: -13px;
    left: 50%;
    margin-left: -20px;
}