.textStyleSelected {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #f8ca42;
}

.textStyleNotSelected {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #c2ceed;
}

.textStyleWhite {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
}

.circleRadioSelected {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid #f8ca42;
  margin-right: 20px;
  cursor: pointer;
}

.circleRadioNotSelected {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #c2ceed;
  margin-right: 20px;
  cursor: pointer;
}

.circleRadioWhite {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-right: 20px;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 950px) {
  .textStyleSelected {
    font-weight: 600;
    font-size: 15px;
  }
  .textStyleNotSelected {
    font-weight: 600;
    font-size: 15px;
  }
  .textStyleWhite {
    font-weight: 600;
    font-size: 15px;
  }
  .circleRadioSelected {
    margin-right: 12px;
  }
  .circleRadioNotSelected {
    margin-right: 12px;
  }
  .circleRadioWhite {
    margin-right: 12px;
  }
}

@media only screen and (min-width: 951px) and (max-width: 1350px) {
  .textStyleSelected {
    font-weight: 600;
    font-size: 16px;
  }
  .textStyleNotSelected {
    font-weight: 600;
    font-size: 16px;
  }
  .textStyleWhite {
    font-weight: 600;
    font-size: 16px;
  }
  .circleRadioSelected {
    margin-right: 16px;
  }
  .circleRadioNotSelected {
    margin-right: 16px;
  }
  .circleRadioWhite {
    margin-right: 16px;
  }
}
