.directionCol {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.noPadding {
  display: flex;
  padding: 0;
}
.cardLine {
  background: #bc94f8;
  border-radius: 20px 0px 0px 20px;
  width: 14px;
  height: 265px;
  margin: 0;
  margin-top: 2px;
}

.cardStyle {
  border-radius: 15px;
  background: #ffffff;
  max-width: 230.51px;
  width: 100%;
  height: 370px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  padding: 0;
}

.headdingTextStyleCardWithColor {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-style: normal;
  color: #1e163f;
}

.textStyleCardWithColor {
  font-family: Cabin, sans-serif;
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  color: #1e163f;
}

@media only screen and (min-width: 767px) and (max-width: 1050px) {
  body .cardStyle {
    width: 92%;
    height: 200px;
  }
  .headdingTextStyleCardWithColor {
    font-weight: 600;
    font-size: 10px;
  }
  .textStyleCardWithColor {
    font-size: 10px;
  }
  .cardLine {
    height: 198px;
    margin-top: 2px;
    width: 12px;
  }
}

@media only screen and (min-width: 1051px) and (max-width: 1280px) {
  body .cardStyle {
    width: 92%;
    height: 230px;
  }
  .headdingTextStyleCardWithColor {
    font-weight: 800;
    font-size: 14px;
  }
  .textStyleCardWithColor {
    font-size: 14px;
  }
  .cardLine {
    height: 230px;
    margin-top: 2px;
    width: 12px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1700px) {
  body .cardStyle {
    height: 270px;
  }
  .headdingTextStyleCardWithColor {
    font-weight: 800;
    font-size: 20px;
  }
  .textStyleCardWithColor {
    font-size: 16px;
  }
  .cardLine {
    height: 270px;
    margin-top: 1.2px;
    width: 12px;
  }
}

@media only screen and (min-width: 1701px) {
  body .cardStyle {
    height: 300px;
  }
  .headdingTextStyleCardWithColor {
    font-weight: 800;
    font-size: 1.7rem;
  }
  .textStyleCardWithColor {
    font-size: 18px;
  }
  .cardLine {
    height: 300px;
    margin-top: 1px;
    width: 15px;
  }
}
