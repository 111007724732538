.video-player {
  .react-player__shadow {
    display: none !important;
  }

  .video-player__container {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;

    .video-thumbnail__container {
      position: relative;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center center;

      .video-info {
        position: absolute;
        bottom: 0;
        z-index: 999;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 22px 30px;
        width: 100%;
        height: 215px;
        background: linear-gradient(0deg, #000000 -12.32%, rgba(34, 34, 34, 0) 89.43%);
        margin-bottom: -10px;

        .video-info__text {
          font-family: 'Catamaran', sans-serif;
          color: #ffffff;
          text-align: left;

          p {
            font-family: 'Catamaran', sans-serif;
            font-weight: 800;
            font-size: 24px;
            line-height: 110%;
            color: #ffffff;
            margin-top: 10px;
            margin-bottom: 0;
          }

          span {
            font-family: 'Cabin', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
          }
        }

        button {
          font-family: 'Catamaran', sans-serif;
          font-weight: 800;
          font-size: 18px;
          color: #1e163f;
          background-color: #f8ca42;
          border: none;
          outline: none;
          padding: 10px 20px;
        }
      }
    }

    .premiumVideoText {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(34, 34, 34, 0.8);
      z-index: 999;

      h3 {
        font-family: 'Catamaran', sans-serif;
        font-size: 28px;
        font-weight: 800;
        max-width: 413px;
        color: #fdfbf9;
      }

      a {
        font-family: 'Catamaran', sans-serif;
        color: #f8ca42;
        font-size: 16px;
        font-weight: 800;
      }
    }
  }

  .thumbnail-slider {
    position: relative;

    .prev-arrow,
    .next-arrow {
      border: none;
      box-shadow: none;
      position: absolute;
      display: flex;
      width: 200px;
      height: 100%;
      top: 0;
      z-index: 99;
    }

    .prev-arrow {
      justify-content: flex-start;
      align-items: center;
      background: linear-gradient(90deg, #f1f5f7 4.66%, rgba(241, 245, 247, 0) 91.22%);
      left: 0;
    }

    .next-arrow {
      justify-content: flex-end;
      align-items: center;
      background: linear-gradient(270deg, #f1f5f7 4.66%, rgba(241, 245, 247, 0) 91.22%);
      right: 0;
    }

    .slick-slider {
      .slick-prev,
      .slick-next {
        display: flex;
        width: 200px;
        height: 100%;
        bottom: 40px;
        z-index: 99;

        &:before {
          font-size: 15px;
          color: #ffffff;
          font-weight: 800;
          background-color: #4b3ad3;
          width: 42px;
          height: 42px;
          padding: 15px;
          border-radius: 50%;
        }
      }

      .slick-slide {
        .premiumVideoText {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(34, 34, 34, 0.8);
          z-index: 99;

          &.video--small {
            img {
              width: 35px;
              height: 35px;
              margin-bottom: 10px;
            }
            p {
              font-family: 'Catamaran', sans-serif;
              font-weight: 800;
              font-size: 14px;
              max-width: 158px;
              line-height: 120%;
              color: #fdfbf9;
            }
          }

          h3 {
            font-family: 'Catamaran', sans-serif;
            font-size: 28px;
            font-weight: 800;
            max-width: 413px;
            color: #fdfbf9;
          }

          a {
            font-family: 'Catamaran', sans-serif;
            color: #f8ca42;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }
    }
  }

  .imageThumbnailStyle {
    width: 100%;
    padding-top: 54%;
  }

  .video-slides__container {
    width: 100%;
    height: 100%;
    padding: 0;

    .slick-list {
      border-radius: 20px;
    }

    .slick-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .slide-inner {
        position: relative;
      }

      .imageThumbnailStyle img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .thumbnail-slides__container {
    width: 100%;
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;

    .slick-slide {
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0.5;
      cursor: pointer;

      &.slick-current {
        opacity: 1;
      }

      .slide-inner {
        position: relative;
        margin: 10px;

        .thumbnail__wrapper {
          border-radius: 10px;
          overflow: hidden;
          position: relative;

          .viewed-video {
            position: absolute;
            background-color: #22c172;
            font-family: 'Cabin', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 150%;
            color: #ffffff;
            display: flex;
            align-items: center;
            padding: 2px 5px;
          }
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        p {
          font-family: 'Catamaran', sans-serif;
          font-weight: 800;
          font-size: 16px;
          line-height: 120%;
          color: #222222;
          margin-top: 10px;
          margin-bottom: 0;
        }

        span {
          font-family: 'Cabin', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
        }
      }

      &.slick-current {
        p {
          color: #4b3ad3;
        }
      }
    }
  }
}
