.settings__container {
  height: 100%;
  padding: 30px 15px 50px;
  .cardsStyling {
    background: #ffffff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 20px 40px;
  }

  .settingHeadingStyle {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    color: #222222;
    line-height: 120%;
  }

  .divBetweenSetting {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .textStyleSetting {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #222222;
    word-wrap: break-word;
  }

  .inputFieldStyle {
    .ant-select-selection-item {
      font-family: Cabin, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      .school-details {
        color: #5b6a9a;
        font-family: Cabin, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
