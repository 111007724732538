.cardBillingStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editDetailStyle {
  display: flex;
  align-items: center;
  width: 80px;
}
.dividerBilling {
  border: 1px solid #c2ceed;
  background: #c2ceed;
}
.cardHight {
  height: 85px;
}
.radioInline{
  width: 260px;
  display: flex;
  justify-content: space-between;
}
.memberStyle {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #222222;
}

.secondRadioInline{
  width: 100px;
}
.managedividerStyleOne {
  border: 1px solid #222222;
  background: #222222 ;
}

.managedividerStyleTwo {
  border: 3px solid #222222;
  background: #222222 ;
}