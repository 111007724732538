.helpful-links {
  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 20px;

      a {
        font-family: Catamaran, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin-bottom: 0;
        }

        img {
          width: 15px;
          height: 15px;
          margin-left: 25px;
        }
      }

      button {
        font-family: Catamaran, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        color: #3a2ea6;

        background: none;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin-bottom: 0;
        }

        img {
          width: 15px;
          height: 15px;
          margin-left: 25px;
        }
      }
    }
  }
}
