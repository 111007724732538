.edubyte-related__container {
  .wrapper {
    h3 {
      font-family: Catamaran, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 120%;
    }
  }
}
