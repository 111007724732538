.teachingResourceCardStyle {
  border-radius: 15px;
  background: #ffffff;
  width: 97%;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);

  &.ant-card-loading {
    overflow: hidden;
    padding: 25px;
    height: 112px;
    .ant-skeleton-paragraph {
      li:nth-of-type(n + 3) {
        display: none;
      }
    }
  }
}

.teachingResourceCardMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.teachingResourceCardSecondMainDivSecond {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
}

.teachingResourceCardSecondMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.teachingResourceCardSecondInnerDiv {
  display: flex;
  align-items: center;
  font-family: Lexend, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  gap: 5px;

  span {
    white-space: nowrap;
  }
}

.teachingResourceInnerCardHeading {
  font-family: Lexend, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.teachingResourceInnerCardDate {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #222222;
}

.teachingResourceInnerCardGrade {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 12px;
  color: #222222;
  text-transform: uppercase;
}

.teachingResourceInnerCardTopic {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #222222;
}

.cardLineteachingResource {
  border-radius: 15px 0px 0px 15px;
  width: 14px;
  height: 80px;
  margin: 0;
  margin-top: 1px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
}

.cardLinePast {
  border-radius: 15px 0px 0px 15px;
  width: 14px;
  height: 111px;
  margin: 0;
  margin-top: 1px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  background: #7ed46a;
}

.memberShipImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.memberShipImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editAccountDetail {
  display: flex;
  align-items: center;
  width: 200px;
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  body .teachingResourceInnerCardHadding {
    font-weight: 800;
    font-size: 12px;
  }

  .teachingResourceInnerCardDate {
    font-size: 10px;
  }

  .teachingResourceCardSecondMainDiv {
    width: 110px;
    display: flex;
    align-items: baseline;
  }

  .teachingResourceCardSecondMainDivSecond {
    display: flex;
    align-items: baseline;
    width: 130px;
  }

  .editAccountDetail {
    display: flex;
    align-items: center;
    width: 95px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  body .teachingResourceInnerCardHadding {
    font-weight: 800;
    font-size: 14px;
  }

  .teachingResourceInnerCardDate {
    font-size: 12px;
  }

  .teachingResourceCardSecondMainDiv {
    width: 140px;
    display: flex;
    align-items: baseline;
  }

  .teachingResourceCardSecondMainDivSecond {
    display: flex;
    align-items: baseline;
    width: 150px;
  }

  .editAccountDetail {
    width: 120px;
  }
}

@media only screen and (min-width: 1901px) {
  body .teachingResourceInnerCardHeading {
    font-weight: 800;
    font-size: 26px;
  }

  .teachingResourceInnerCardDate {
    font-size: 18px;
  }

  .teachingResourceCardSecondMainDiv {
    width: 250px;
    display: flex;
    align-items: baseline;
  }
}

.editAccountDetail {
  display: flex;
  width: auto;
}
