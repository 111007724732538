.forgetPasswordTextStyle {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  max-width: 585px;
  text-align: center;
}
.textStyleOrStyle {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-right: 0.25rem ;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  body .forgetPasswordTextStyle {
    max-width: 330px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1350px) {
  body .forgetPasswordTextStyle {
    max-width: 400px;
  }
}
