.referalMainHeight {
  min-height: 100vh;
  height: 100%;
}
.referalHadding {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  color: #222222;
}
.referalFirstBackgroundChange {
  background: #f1f5f7;
  align-items: center;
  height: 55px;
}
.referalSecondBackgroundChange {
  background: #ffffff;
  align-items: center;
  height: 55px;
}
.panelHeadingsStyleReferal {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 18px;
  font-style: normal;
  color: #222222;
  background-color: none;
}

.referalIconStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #4b3ad3;
  margin: 5px;
}
.cardWidth {
  width: 100%;
}
.wordBreak {
  word-break: break-all;
}
.centerText {
  display: flex;
}

.faqAnswer {
  font-weight: 500;
}
