.assignmentCardStyle {
  border-radius: 15px;
  background: #ffffff;
  width: 97%;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);

  &.ant-card-loading {
    overflow: hidden;
    padding: 25px;
    height: 112px;
    .ant-skeleton-paragraph {
      li:nth-of-type(n + 3) {
        display: none;
      }
    }
  }
}

.assignmentCardMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.assignmentCardSecondMainDivSecond {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
}

.assignmentCardSecondMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.assignmentCardSecondInnerDiv {
  display: flex;
  align-items: center;
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.2rem;
  color: #4b3ad3;
}

.assignmentInnerCardHeading {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #222222;
}

.assignmentInnerCardDate {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #222222;
}

.assignmentInnerCardGrade {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 12px;
  color: #222222;
  text-transform: uppercase;
}

.assignmentInnerCardTopic {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #222222;
}

.cardLineAssignment {
  border-radius: 15px 0px 0px 15px;
  width: 8.25px;
  height: 95px;
  margin: 0;
  margin-top: 1px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
}

.cardLinePast {
  border-radius: 15px 0px 0px 15px;
  width: 11.59px;
  height: 111px;
  margin: 0;
  margin-top: 1px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  background: #7ed46a;
}

.memberShipImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.memberShipImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editAccountDetail {
  display: flex;
  align-items: center;
  width: 200px;
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  body .assignmentInnerCardHadding {
    font-weight: 800;
    font-size: 12px;
  }

  .assignmentInnerCardDate {
    font-size: 10px;
  }

  .assignmentCardSecondMainDiv {
    width: 110px;
    display: flex;
    align-items: baseline;
  }

  .assignmentCardSecondMainDivSecond {
    display: flex;
    align-items: baseline;
    width: 130px;
  }

  .editAccountDetail {
    display: flex;
    align-items: center;
    width: 95px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  body .assignmentInnerCardHadding {
    font-weight: 800;
    font-size: 14px;
  }

  .assignmentInnerCardDate {
    font-size: 12px;
  }

  .assignmentCardSecondMainDiv {
    width: 140px;
    display: flex;
    align-items: baseline;
  }

  .assignmentCardSecondMainDivSecond {
    display: flex;
    align-items: baseline;
    width: 150px;
  }

  .editAccountDetail {
    width: 120px;
  }
}

@media only screen and (min-width: 1901px) {
  body .assignmentInnerCardHeading {
    font-weight: 800;
    font-size: 26px;
  }

  .assignmentInnerCardDate {
    font-size: 18px;
  }

  .assignmentCardSecondMainDiv {
    width: 250px;
    display: flex;
    align-items: baseline;
  }
}

.editAccountDetail {
  display: flex;
  width: auto;
}
