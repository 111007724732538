.lang-switcher__container {
  background-color: #ffffff;
  padding: 12px 10px;
  margin-right: 10px;
  border-radius: 50px;
  cursor: pointer;
  width: 100px;

  font-family: Catamaran, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;

  .lang-switcher--current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .flag {
      margin-right: 5px;
    }

    .current-language {
      margin-right: 5px;
    }

    .anticon {
      font-size: 12px;
    }
  }
}

.lang-switcher__dropdown {
  button {
    font-family: Catamaran, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: #000000;

    .flag {
      margin-right: 5px;
    }
  }
}
