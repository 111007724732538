.cardBillingStyle {
  display: flex;
  justify-content: space-between;
}

.editDetailStyle {
  display: flex;
  align-items: center;
  width: 80px;
}

.dividerBilling {
  border: 1px solid #c2ceed;
  background: #c2ceed;
}

.cardHight {
  height: 85px;
}

.haddingStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 28px;
  font-style: normal;
  color: #222222;
}

.cardImageStyle {
  width: 50px;
  height: 40px;
}

.cardNumberStyle {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #1e163f;
  display: flex;
  align-items: center;
}

.expStyle {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #1e163f;
  display: flex;
  align-items: center;
}

.addNewCardText {
  font-family: Cabin, sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: #001e3a;
}

.copyMessage {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}

.copyMessage__text {
  margin: 0;
  padding: 0;
}

.add-card-info,
.update-card-info {
  button {
    width: 100%;
  }

  .fixCardInputField {
    &.card-info {
      padding: 12px 10px;
    }

    &.user-info {
      padding: 9px 10px;
    }
  }
}
