.refer_teacher_modal__container {
  display: flex;
  flex-direction: column;
  text-align: center;

  h3 {
    font-family: Catamaran, sans-serif;
  }

  .modal_image {
    display: flex;
    justify-content: center;
  }
}
