.alignment-modal__container {
  height: 560px;
  overflow: auto;

  .alignment-modal__header {
    margin-bottom: 20px;
  }

  .alignment-modal__heading--sub {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    color: #222222;
  }

  .alignment-modal__text--sub {
    font-family: Cabin, sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: #001e3a;
    word-break: break-word;
  }

  .ant-table-container {
    .ant-table-thead {
      .ant-table-cell {
        font-family: Catamaran, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        color: #222222;
        background: #ffffff;
        border: none;
        &:before {
          background: transparent;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        &:nth-child(odd) {
          background: #f1f5f7;
        }

        .ant-table-cell {
          border: none;
        }
      }
    }
  }
}
