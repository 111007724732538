.referral_progress__container {
  .invitation__table {
    .ant-table-thead {
      .ant-table-cell {
        font-size: 16px;
        font-weight: 800;
        line-height: 19px;
        color: #222222;
        background-color: transparent;
        &:before {
          display: none;
        }
      }
    }

    .ant-table-footer {
      background-color: transparent;
    }

    .resend__button {
      border: none;
      background-color: transparent;
      font-family: Catamaran, sans-serif;
      font-size: 14px;
      font-weight: 800;
      line-height: 14px;
      color: #ffa828;
    }
  }

  .ant-tag {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    border-radius: 16px;
    padding: 6px 10px;
    border: none;
  }
  .ant-tag-geekblue {
    background-color: #cfd8dc; /* Placeholder color */
  }

  .ant-tag-gold {
    background-color: #ffe78d;
    color: #753e12;
  }

  .ant-tag-green {
    background-color: #96bf30;
    color: #ffffff;
  }
}
