.card-on-file__container {
  .cardsStyling {
    padding: 30px;
  }

  .addNewCardText {
    font-family: Cabin, sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: #001e3a;
  }

  .expStyle {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #1e163f;
    display: flex;
    align-items: center;
  }

  .cardNumberStyle {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #1e163f;
    display: flex;
    align-items: center;
  }

  .cardImageStyle {
    width: 50px;
    height: 40px;
  }

  .cardHeight {
    height: auto;
  }

  .cardBillingStyle {
    display: flex;
    justify-content: space-between;
  }

  .editDetailStyle {
    width: 100px;
  }
}
