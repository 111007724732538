.pagination__container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #4b3ad3;
    line-height: 24px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    width: 26px;
    height: 26px;
    &:disabled {
      color: #c2ceed;
      cursor: not-allowed;
    }
    &.active {
      color: #ffffff;
      background: #4b3ad3;
      border-radius: 50%;
    }
  }
}
