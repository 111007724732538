.edubyte-detail__container {
  padding-bottom: 80px;
  .edubyte-detail__main {
    height: 100%;
    width: 100%;
  }

  .edubyte-detail__header {
    background: #ffffff;
    margin: 0 -16px;
    padding: 0 16px;

    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .edubyte-detail__heading--sub {
    font-family: Catamaran, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    color: #222222;
  }

  .edubyte-detail__text--sub {
    font-family: Cabin, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #222222;
    word-break: break-word;
  }

  .how-to-teach__text--container {
    padding: 25px 20px;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 10px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: Catamaran, sans-serif;
      font-style: normal;
    }

    p,
    li,
    a {
      font-family: Cabin, sans-serif;
      font-weight: 400;
    }
  }

  .curriculum-list {
    ul {
      padding-left: 20px;
      li {
        font-family: Cabin, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    .text__link {
      border: none;
      color: #4b3ad3;
      font-family: Catamaran, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 15px;
    }
  }
}
