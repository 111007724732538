.headingStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 28px;
  font-style: normal;
  color: #222222;
}

.add-membership__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  height: 80px;
  background: #ffffff;
  border-radius: 15px;
  margin-right: 15px;

  button {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    color: #4b3ad3;
    border: none;
    background: none;
  }
}

.available-coupon__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;

  h3 {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
  }

  p {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    a {
      color: #4b3ad3;
      font-weight: 700;
    }
  }

  .subscription__input {
    h4 {
      font-family: Catamaran, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 120%;
      color: #4b3ad3;
    }

    span {
      font-family: Cabin, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }

    .coupon-code {
      background: #ffffff;
      border: 2px solid #c2ceed;
      padding: 19px 22px;
      margin-bottom: 10px;
      font-family: Cabin, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
    }
  }
}
