.cardBillingStyle {
  display: flex;
  justify-content: space-between;
}
.editDetailStyle {
  display: flex;
  align-items: center;
  width: 80px;
}
.dividerBilling {
  border: 1px solid #c2ceed;
  background: #c2ceed;
}
.cardHight {
  height: 85px;
}
.radioInline{
  width: 260px;
  display: flex;
  justify-content: space-between;
}
.radioInline2{
  width: 260px;
  display: flex;
  justify-content: space-between;
}
.memberStyle {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #222222;
}

.secondRadioInline{
  width: 100px;
}
.managedividerStyleOne {
  border: 1px solid #222222;
  background: #222222 ;
}

.managedividerStyleTwo {
  border: 3px solid #222222;
  background: #222222 ;
}
.planRadioStyle{
  display: flex;
}
.manageSubscriptionFirstCard {
  width: 97%;
  /* height: 584px; */
  min-height: 85%;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  body .planRadioStyle {
    display:contents;
  }
  .radioInline2{
    width: 260px;
    display: flex;
    flex-direction: column;
    height: 70px;
    padding-left: 5px;
  }

}
@media only screen and (min-width: 901px) and (max-width: 1200px) {
  body .planRadioStyle {
    display:contents;
  }
 
}