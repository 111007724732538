.heightSidebar {
  height: 100vh;
}

.backgroundColor {
  background-color: #1e163f;
  min-width: 230px !important;
}

.logoStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
}
.menuItemStyle {
  margin-left: 3px;
  margin-top: 10px;
  margin-bottom: 6px;
  display: flex;
  word-break: break-word;
  white-space: normal;
  line-height: 1.5rem;
}
.imageSideBarStyle {
  margin-bottom: 4px;
}
.lineStyle {
  color: #3a2ea6;
  border: 1px solid #3a2ea6;
}

.iconSize {
  font-size: 20px;
}

.marginContent {
  margin: 0 16px 100px;
}
.siderbarAuthenticated {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #222222;
  text-align: center;
  background-color: #ffffff;
}
.siderbarAuthenticated:hover {
  color: #4b3ad3;
}
.addNewClass {
  height: 24px;
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #4b3ad3;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #c2ceed;
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}

.fixedSidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.ant-menu-submenu-popup {
  height: 100vh;
  left: 230px !important;
  top: 0 !important;

  .ant-menu {
    background-color: #271d52;
    height: 100%;
    max-height: calc(100% - 32px);
    border-radius: 0;
    padding: 10px;

    .ant-menu-item {
      background-color: #271d52;
    }
  }
}

.sideBarStyle {
  font-size: 16px;
  border: none;
  background-color: #1e163f !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 192px !important;
}

.ant-menu:not(.ant-menu-horizontal) {
  .ant-menu-item-selected {
    background-color: #3a2ea6 !important;
    border-radius: 5px !important;
    height: 58px !important;
    font-weight: 800 !important;
    font-family: Catamaran, sans-serif !important;
    font-style: normal !important;
    font-size: 16px !important;
    color: #ffffff;
    width: 192px !important;
  }

  .ant-menu-submenu-open .ant-menu-submenu-title {
  }
}

.ant-menu-inline.ant-menu-root {
  .ant-menu-item {
    display: flex;
    align-items: center;
    height: 53px;
    width: 192px;
    background-color: #1e163f;
    font-weight: 800;
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 15px;
    color: #c2ceed;
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      height: 53px;
      width: 192px;
      background-color: #1e163f;
      font-weight: 800;
      font-family: Catamaran, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 15px;
      color: #c2ceed;
    }
    .ant-menu-submenu-arrow {
      color: #f1f5f7;
    }
  }

  .ant-menu-sub.ant-menu-inline {
    .ant-menu-item {
      margin: 0;
    }

    .ant-menu-item-selected {
      border-radius: 0 !important;
    }
  }

  .ant-menu-item:hover,
  .ant-menu-submenu:hover .ant-menu-submenu-title {
    color: #f8ca42;
  }

  .ant-menu-item:hover svg,
  .ant-menu-submenu:hover svg {
    color: #f8ca42;
    stroke: #f8ca42;
  }
  .ant-menu-item svg,
  .ant-menu-submenu svg {
    color: #c2ceed;
    stroke: #c2ceed;
  }

  .ant-menu-item-selected:after,
  .ant-menu-selected:after,
  .ant-menu-submenu-open:after {
    opacity: 0;
  }
}
