.footer {
  text-align: center;
  background-color: #1e2632;
  color: white;
  padding: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  z-index: 99;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}
