.buttonStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  background: #f8ca42;
  min-height: 62px;
  max-width: 100%;
  width: 100%;
  color: #1e163f;
  text-align: center;
  border: none;
  padding: 12px 20px;
}

.buttonStyle:hover {
  background: #f6bc11;
}

.buttonDisabledStyle {
  background: #f8ca42;
  opacity: 0.7;
  color: #1e163f;
  border: 2px solid #ffff;
}

.buttonDisabledCoupon {
  background: #f8ca42;
  width: 158px;
  height: 48px;
  opacity: 0.7;
  color: #1e163f;
  border: 3px solid #f8ca42;
  margin-right: -3px;
}

.buttonOutlineStyle {
  background: #fff;
  border: 2px solid #f8ca42;
}

.buttonTextStyle {
  color: #674eff;
  background: transparent;
  border: none;
  &:hover {
    background: transparent;
  }
}
