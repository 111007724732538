h1,
h2,
h3,
h4 {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: 800;
  color: #000000;
}

h1 {
  font-size: 72px;
  line-height: 110%;
}

h2 {
  font-size: 42px;
  line-height: 110%;
}

h3 {
  font-size: 28px;
  line-height: 110%;
}

h4 {
  font-size: 20px;
  line-height: 120%;
}

p {
  font-family: Cabin, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &.large {
    font-size: 42px;
    line-height: 140%;
  }

  &.small {
    font-size: 14px;
    line-height: 150%;
  }

  &.subtitle {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: #000000;
  }
}

// Since all the required (*) are on the right, this will override the default behaviour
.ant-form-item-required {
  flex-direction: row-reverse;
}

.ant-form-item-required::before {
  margin-left: 4px;
}

.ant-form-item-required::after {
  width : 0;
  margin: 0 !important;
}
