.select-licence__container {
  .select-licence__options {
    display: flex;
    font-size: inherit;
    gap: 1rem;
    margin-right: 1rem;
  }

  .licence-option__container {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    background-color: #f5f5f5;
    margin-bottom: 1rem;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;

    &.selected {
      background-color: #efeafd;
      border: 2px solid #674eff;
    }

    .licence-option__header {
      .licence-option__radio {
        display: flex;
        align-items: center;
      }

      .licence-option__title {
        font-size: 1rem;
        font-weight: 800;
        font-family: Catamaran, sans-serif;
        color: #1e163f;
        text-transform: uppercase;
        margin-top: 0.3rem;
        margin-bottom: 0;
      }

      .licence-option__price {
        font-family: Catamaran, sans-serif;
        font-size: 3.5rem;
        font-weight: 800;
        color: #4b3ad3;
        text-align: center;
        margin-bottom: 1rem;
      }

      .premium-year {
        font-family: Catamaran, sans-serif;
        font-size: 1rem;
        font-weight: 800;
        color: #4b3ad3;
        margin-left: 0.5rem;
        margin-top: 0.5rem;
      }
    }

    .licence-option__features {
      margin-left: 0;
      padding: 0;
      .licence-option__feature {
        display: flex;
        align-items: baseline;
        font-family: Lexend, sans-serif;
        font-size: 1rem;
        color: #1e163f;
        list-style: none;
        margin-bottom: 0.5rem;

        img {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
