.create-account__container {
  .ant-form {
    max-width: 586px;
  }

  .principal_check {
    h4 {
      color: #ffffff;
      font-family: 'Catamaran', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
    }

    .principal_check__wrapper {
      label {
        color: #ffffff;
        font-family: Cabin, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .t-and-c {
    text-align: left;
    margin-left: 10px;
    line-height: 20px;
  }
}
