@import './styles/global.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cleverStyle {
  font-weight: bold;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 22px;
  background: #3a61f0;
  height: 70px;
  width: 583px;
  color: white;
  text-align: center;
  border: 3px solid #3a61f0;
  border-radius: 5px;
}

.buttonStyle.googleStyle {
  font-weight: bold;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 22px;
  color: #020203;
  text-align: center;
  background: #ffffff;
  border: 3px solid #020203;
  border-radius: 5px;
}

.fixCardInputFieldTwo {
  background: #ffffff;
  border: 2px solid #c2ceed;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #222222;
  width: 100%;
  height: 50px;
}
.fixCardInputField {
  background: #ffffff;
  border: 2px solid #c2ceed;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #222222;
  width: 100%;
}

.selectFieldSelect {
  font-family: Cabin, sans-serif;
  font-weight: 400;
  font-size: 18px !important;
  font-style: normal;
  background: #ffffff;
  height: 50px;
  width: 586px !important;
  border: #c2ceed;
  cursor: pointer;
}

@media only screen and (min-width: 767px) and (max-width: 950px) {
  body .cleverStyle {
    width: 320px;
  }
  .googleStyle {
    width: 320px;
  }
  .fixCardInputFieldTwo {
    width: 90%;
  }
  .fixCardInputField {
    width: 100%;
  }
  body .selectFieldSelect {
    width: 320px !important;
  }
}

@media only screen and (min-width: 951px) and (max-width: 1350px) {
  body .googleStyle {
    width: 380px;
  }
  .cleverStyle {
    width: 380px;
  }
  .fixCardInputFieldTwo {
    width: 90%;
  }
  .fixCardInputField {
    width: 100%;
  }
  body .selectFieldSelect {
    width: 380px !important;
  }
}

.cursorPointer {
  cursor: pointer;
}

.selectTextStyle {
  font-weight: 800;
  font-family: Catamaran, sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: 20px;
  background: #1e163f;
  border: none;
  width: 80%;
}

.selectTextMain {
  display: flex;
  justify-content: center;
  background: #1e163f !important;
}

.ant-card-body {
  padding: 0 !important;
}

.ant-modal-footer {
  padding: 0 !important;
  background: transparent;
  border-top: none !important;
  border-radius: 0 !important;
}

.ant-modal-content {
  position: unset;
  border-radius: 0 !important;
  box-shadow: none !important;
  width: 100%;
}

.ant-modal-body {
  height: 100%;
}

.fixCardHeadingStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 28px;
  font-style: normal;
  color: #222222;
}
.fixCradCenter {
  display: flex;
  justify-content: center;
  text-align: center;
}

.fixCardtextStyle {
  font-family: Lexend, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3B3D3C;
  min-width: 520px;
}

.deleteButtonStyling {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #4b3ad3;
  display: flex;
  justify-content: center;
  align-items: center;

  &:is(button) {
    border: none;
    background: transparent;
  }
  &:hover {
    color: #4b3ad3;
  }
}

input:focus-visible {
  outline: none !important;
}

.spaceBetweenDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-input-affix-wrapper {
  padding: 0px;
  padding-left: 11px;
}

.ant-divider-horizontal {
  margin: 12px 0;
}

.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #c2ceed;
  border-radius: 50%;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #c2ceed;
}
.ant-radio-inner:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  background-color: #4b3ad3;
  border-top: 0;
  border-left: 0;
  border-radius: 50px;
}

.ant-radio-wrapper {
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
}

.ant-form label {
  font-size: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  body .ant-form label {
    font-size: 13px;
  }
}

.ant-form-item {
  margin: 0 0 8px !important;
  transition: none;
  line-height: 0px !important;
}
.ant-checkbox .ant-checkbox-inner {
  width: 28px;
  height: 28px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: #4b3ad3 !important;
  border-color: #4b3ad3 !important;
  width: 28px;
  height: 28px;
}

.tooltip-button {
  background: #D4DFFC !important;
  border-color: #D4DFFC !important;
  width: 22px !important;
  height: 22px !important;

  :hover {
    background: #4b3ad3 !important;
    border-color: #4b3ad3 !important;
    width: 22px !important;
    height: 22px !important;
    border-radius: 50%;
  }
}

.ant-checkbox-inner::after {
  width: 10px;
  height: 17px;
  left: 5px;
  top: 12px;
}

.ant-select-single:not(.ant-select-customize-input,.customInputField) .ant-select-selector {
  width: 100% !important;
  height: 45px !important;
  padding: 0 11px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  border-radius: 0px !important;
  transition: none !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
  font-weight: 800;
  text-transform: capitalize;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  height: auto !important;
  padding: 20px 40px 20px 40px;
  align-items: center;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  width: 40%;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-content .ant-collapse-content-box {
  padding: 16px 20px 16px 40px;
}

.ant-form-item-explain-error {
  width: 100% !important;
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  body .ant-form-item-explain-error {
    width: 320px !important;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1351px) {
  body .ant-form-item-explain-error {
    width: 380px !important;
  }
}

.fixCardtext {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #222222;
  min-width: 520px;
}

.ant-input-password-icon {
  align-self: center;
  padding-left: 8px;
  padding-right: 10px;
  font-size: x-large;
  color: #3a2ea6;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  min-height: 45px !important;
}

.ant-select-multiple .ant-select-selection-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  background: #c2ceed;
  border-radius: 255px;
  width: 100%;
  height: 27px;
}

.row {
  --bs-gutter-x: 1rem !important;
}

.ant-modal-close-x {
  color: #c2ceed;
  font-size: 24px;
}

.ant-modal {
  width: 585px;

  &.wide {
    width: 623px !important;
  }
}

.borderChange {
  border-radius: 15px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: none;
}

.selectStyleColor {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #ffffff;
  width: 195px;
  text-align: center;
  > .ant-select-selector {
    box-shadow: none !important;
  }
}
.selectStyleColor::first-letter {
  text-transform: uppercase;
}

div::first-letter {
  text-transform: uppercase;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::first-letter {
  text-transform: uppercase;
}

.capital {
  text-transform: capitalize;
}

.spineerCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-message-custom-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-switch-handle {
  top: 6px;
  left: 4px;
  width: 21px;
  height: 21px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 26px);
}
.ant-select-arrow {
  color: #4b3ad3;
  font-size: 14px;
}

.ant-picker-input > input {
  font-size: 22px;
  background: none;
  border: none;
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  color: #020203;
}
.ant-picker-separator {
  color: #020203;
  font-size: 30px;
  height: 30px;
}
.ant-picker-input > input::placeholder {
  color: #020203;
  opacity: 1;
}
.ant-picker {
  font-size: 30px;
}
.widthFix {
  width: 546px;
}

.ant-skeleton-element {
  width: 100%;
  height: 100%;
}

.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 50px;
}

.ant-switch {
  background: #c2ceed;
  border-radius: 255px;
  width: 89px;
  height: 45px;

  &.ant-switch-checked {
    .ant-switch-handle:before {
      left: -16px;
    }
  }

  .ant-switch-handle:before {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    top: -2px;
  }
}

.dashboard-link {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: #3a2ea6;
  background-color: transparent;
  border: none;
}

.site-layout-background .container-fluid {
  max-width: 1280px;
}

.childBackgroundDStyle {
  .top-nav {
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    .lang-switcher__container {
    }
  }
}

.ant-tooltip-inner {
  border-radius: 5px;
  padding: 16px 10px;
}
