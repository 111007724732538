.parent-resources__card {
  display: flex;
  .parentResourcesMainStyle {
    max-width: 281px;
    background: #ffffff;
    box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    cursor: pointer;
  }
  .parentResourcesStyle {
    max-width: 300px;
    border-radius: 20px 20px 0px 0px;
  }
  .spaceBetweenResouces {
    display: flex;
  }
  .parentWhiteColorTextStyle {
    display: flex;
    justify-content: center;
    width: 100%;
    object-fit: fill;
    height: 200px;
    border-radius: 20px 20px 0px 0px;
  }

  .lockDivStyle {
    display: flex;
    justify-content: flex-end;
  }
  .lockImageStyle {
    width: 20px;
    height: 20px;
    color: red;
  }
  .premiumOuterDivStyle {
    display: flex;
    justify-content: flex-end;
  }
  .premiumInnerDivStyle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 20px;
  }
  .premiumImageStyle {
    width: 20px;
    height: 20px;
  }
  .premiumMainDivStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
  }

  .parentResoucesMainHadding {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: #1e163f;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.625;
  }
  .parentResoucesTextHadding {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e163f;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.625;
  }

  @media only screen and (min-width: 768px) and (max-width: 950px) {
    .parentResourcesMainStyle {
      max-width: 240px;
    }
    .parentResourcesStyle {
      max-width: 240px;
    }
    .parentWhiteColorTextStyle {
      max-width: 240px;
    }
    .spaceBetweenResouces {
      display: flex;
      flex-direction: column;
    }
    .premiumVideoText {
      left: 5%;
    }
  }
}
