.homeHeading {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 42px;
  font-style: normal;
  color: #ffffff;
  text-align: center;
}
.innerDivHome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}

.cardHome {
  width: 276px;
  height: 227px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.cardHeadingHome {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  color: #1e163f;
}

.cardGetStartedHome {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 15px;
  color: #4b3ad3;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  body .cardHome {
    width: 200px;
  }
}
