.curriculum-setup-container {
  .checkboxStyle {
    display: flex;
    font-family: Cabin, sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: #ffffff;
    align-items: center;
  }
}
