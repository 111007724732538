.billing-info__container {
  .billing-info {
    .bill-info--empty {
      p {
        font-family: Cabin, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        margin: 0;

        span {
          font-family: Catamaran, sans-serif;
          font-weight: 800;
          color: #4b3ad3;
          background: none;
          border: none;
          padding: 0;
        }
      }
    }

    .billing-info__card {
      .card__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card-details {
          display: flex;
          gap: 15px;
          align-items: center;

          img {
            height: auto;
          }
          .card-number {
            font-family: Catamaran, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 120%;
          }

          .expiry-date {
            font-family: Cabin, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #1e163f;
          }
        }

        .actions {
          button {
            background-color: transparent;
            border: none;
            font-family: Catamaran, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 15px;
            color: #4b3ad3;
          }
        }
      }

      p {
        margin: 0;
      }
    }
  }
}
