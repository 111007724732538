.faq__container {
  .ant-collapse {
    background: #ffffff;
    border: none;
  }
  .faq__panel {
    font-family: Lexend, sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;

    .ant-collapse-header {
      padding: 20px 0;

      .ant-collapse-header-text {
        font-family: Lexend, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-right: 30px;
      }
    }

    .ant-collapse-content {
      border: none;
      .ant-collapse-content-box {
        padding: 20px 0;

        p {
          margin-bottom: 0;
        }
      }

      .faqAnswer {
        ul {
          margin: 0;
        }
      }
    }
  }
}
