.refer_friends__container {
  .inputFieldStyle {
    width: 100%;
  }

  label {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
  }

  button {
    min-height: 52px;
    border: none;
  }

  .bottom_box__container {
    background-color: #fffbee;
    margin: 30px -30px -30px;
    padding: 30px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    svg {
      margin-bottom: 20px;
    }

    h4 {
      font-family: Catamaran, sans-serif;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .box_item {
      padding-right: 110px;
    }
  }
}
