.reward_stats__container {
  .reward_stats__content {
    .stats__item {
      margin-bottom: 25px;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: Lexend, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      .credit-count {
        font-family: Catamaran, sans-serif;
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          margin-left: 10px;
        }
      }
    }
  }
}
