.mainDivStyle {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(27, 0, 136, 0.919164) -28.16%, #120b2f 87.57%);
}
.textStyle400Cabin18 {
  font-family: Cabin, sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: #ffffff;
  text-align: center;
}

.textStyleForgetPassword {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.innerDivSignin {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textStyleHeading {
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  max-width: 450px;
  margin: auto;
  color: #ffffff;
  text-align: center;
}
