.emailCodeStyleHeading {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 42px;
  font-style: normal;
  color: #ffffff;
  text-align: center;
  width: 452px;
}

.textStyle {
  font-family: Cabin, sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: #ffffff;
  text-align: center;
  width: 585px;
}
.resendCodeStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #ffffff;
  line-height: 24px;
}
.imageDiv {
  display: flex;
  justify-content: flex-end;
}
.imageResendCodeStyle {
  background: #f8ca42;
  border: 1px solid #f8ca42;
  margin-right: 6.5rem;
}
.heightBabyEmailConfirmation {
  height: 450px;
}
@media only screen and (min-width: 768px) and (max-width: 950px) {
  body .emailCodeStyleHeading {
    font-weight: 600;
    font-size: 35px;
    width: 320px;
  }
  body .textStyle {
    font-size: 16px;
    width: 320px;
  }
  .imageDiv {
    width: 96%;
  }
  .imageResendCodeStyle {
    margin-right: 1rem;
    width: 95px;
  }
}

@media only screen and (min-width: 951px) and (max-width: 1350px) {
  body .emailCodeStyleHeading {
    font-weight: 600;
    font-size: 35px;
    width: 380px;
  }
  body .textStyle {
    font-size: 16px;
    width: 380px;
  }
  .imageDiv {
    width: 99%;
  }
  .imageResendCodeStyle {
    margin-right: 0.1rem;
    width: 89px;
  }
}
