.payment-setup__container {
  .emailCodeStyleHeading {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 42px;
    font-style: normal;
    line-height: 110%;
    text-align: left;
    color: #ffffff;
  }

  .textStylePayment {
    font-family: Cabin, sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: #ffffff;
    display: flex;
    align-items: center;
  }

  .displayInlineNames {
    display: flex;
    justify-content: space-between;
  }

  .learnMoreStyle {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-style: normal;
    color: #ffffff;
    line-height: 24px;
    font-size: 1rem;
    margin-top: 0.25rem;
    border-bottom: 2px solid #f8ca42;
    display: inline-block;
    cursor: pointer;
  }
  .dividerPaymentOne {
    border: 0.5px solid #ffffff;
    background: #ffffff;
  }
  .dividerPaymentTwo {
    border: 2px solid #ffffff;
    background: #ffffff;
    height: 0;
  }
  .textStyleSubtotalPayment {
    font-family: Cabin, sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
  }
  .textStyleTotalPayment {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
  }
  .selectStylePayment {
    font-family: Cabin, sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    background: #ffffff;
    height: 50px;
    width: 100%;
    border: 2px solid #c2ceed;
  }
  .applyCouponButton {
    width: 168px;
    min-height: 52px;
    border: none;
    font-family: Catamaran, sans-serif;
    background-color: #f8ca42;
    font-weight: 800;
    margin-right: 0;
  }

  .paymentInputField {
    height: 50px;
    border-radius: 5px;
    border: 2px solid #c2ceed;
  }
  .paymentStyle {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 36px;
    font-style: normal;
    color: #222222;
    width: 450px;
    text-align: center;
  }

  .divider {
    display: flex;
    margin: 30px 0;

    .dividerStyle {
      width: 48%;
      border: 1px solid #c2ceed;
      background: #c2ceed;
      height: 0;
      margin: 12px 0;
    }
    span {
      font-family: Cabin, sans-serif;
      font-weight: 400;
      font-size: 18px;
      font-style: normal;
      color: #fdfbf9;
      padding: 0 10px;
    }
  }

  .redeem-coupon__container {
    .inputFieldStyle {
      width: 100%;
    }
    .coupon--applied {
      display: flex;
      align-items: center;
      background-color: #c2ceed;
      padding: 20px 15px;
      margin-top: 30px;
      max-width: fit-content;

      button {
        border: none;
        background-color: transparent;
        font-size: 22px;
        width: 22px;
        height: 22px;
        padding: 0;
      }

      span svg {
        font-size: 18px;
      }

      button {
        span {
          display: flex;
        }
        svg {
          font-size: 22px;
        }
      }

      h4 {
        font-family: Catamaran, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 120%;
        text-transform: uppercase;
        margin: 0 30px 0 5px;
      }
    }
  }
}

.form__container {
  .form__heading {
    color: #ffffff;
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
  }

  .fixCardInputField {
    height: 50px;
    border: 2px solid #c2ceed;
    padding: 12px 10px;
  }

  .labelStyleCard,
  label {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    color: #ffffff;
  }

  .ant-checkbox-wrapper {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    .ant-checkbox {
      top: 0.5em;
    }
  }
}
