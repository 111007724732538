.past-invoices__container {
  .invoices {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      .invoice {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #c2ceed;
        padding: 10px 0;
        p {
          font-family: Cabin, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          color: #222222;
          line-height: 24px;
          margin: 0;
        }

        a {
          font-family: Catamaran, sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          color: #4b3ad3;
          line-height: 24px;
        }
      }
    }
  }

  .controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    p {
      font-family: Cabin, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #222222;
      line-height: 150%;
      margin: 0;
    }

    button {
      font-family: Catamaran, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: #4b3ad3;
      line-height: 24px;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      &:disabled {
        color: #c2ceed;
        cursor: not-allowed;
      }
    }
  }
}
