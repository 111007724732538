.SchoolSetupStyleHeading {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 42px;
  font-style: normal;
  color: #ffffff;
  text-align: center;
  width: 452px;
}

.textStyleYellow {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 16px;
  font-style: normal;
  color: #f8ca42;
  text-align: center;
  width: 585px;
}

.schoolSetUpLabel {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-style: normal;
  color: #ffffff;
  line-height: 24px;
}

@media only screen and (min-width: 768px) and (max-width: 950px) {
  body .SchoolSetupStyleHeading {
    font-weight: 600;
    font-size: 35px;
    width: 320px;
  }
  body .textStyleYellow {
    width: 320px;
  }
}

@media only screen and (min-width: 951px) and (max-width: 1350px) {
  body .SchoolSetupStyleHeading {
    font-weight: 600;
    font-size: 35px;
    width: 380px;
  }
  body .textStyle {
    font-size: 14px;
    width: 380px;
  }
}
