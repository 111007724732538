.signed-up__container {
  .innerDivSignin {
    display: flex;
    justify-content: center;
  }

  .textStyleHeading {
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 110%;
    max-width: 450px;
    margin: auto;
  }

  .textStyle400Cabin18 {
    font-family: Cabin, sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: #ffffff;
    max-width: 586px;
    margin: auto;
  }

  .textStyleForgetPassword {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}
