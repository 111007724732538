.overViewMainDivHeight {
  min-height: 100vh;
  width: 100%;
}
.cardStyleOverView {
  border-radius: 15px;
  background: #ffffff;
  width: 583px;
  min-height: 169px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
}
.firstCardSpaceBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dashboardPageScorling {
  overflow: auto;
  min-height: 80vh;
}

.cardHeadingStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 14px;
  font-style: normal;
  color: #222222;
}

.cardMiddleStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 28px;
  font-style: normal;
  color: #4b3ad3;
}
.flexStyle {
  display: flex;
}

.cardTextStyle {
  font-family: Cabin, sans-serif;
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  color: #222222;
  line-height: 26px;
}
.somePadding {
  padding-left: 20px;
}

.cardLineStyle {
  border: 1px solid #c2ceed;
  background-color: #c2ceed;
  min-height: 100%;
}

.teachingResourseMainDivSecond {
  display: flex;
  justify-content: space-between;
  width: 583px;
}
.teachingResourseMainDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}
.teachingResourcesHeadingStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 28px;
  font-style: normal;
  color: #222222;
}

.seeMoreTextStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 16px;
  font-style: normal;
  color: #4b3ad3;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.studentCardStyleOverView {
  border-radius: 15px;
  background: #ffffff;
  height: 549px;
  width: 379px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
}
.studentStyleOverView {
  color: #222222;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.backgroundColorWithText {
  border-radius: 255px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  color: #222222;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  max-width: 200px;
  display: inline-block;
}
.minHeight {
  min-height: 250px;
}
.oulatedStyle {
  color: #4b3ad3;
  font-size: 13px;
}
.emptyMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 180px;
  color: #222222;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.welcome-text {
  font-family: Cabin, sans-serif;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.create-class__button {
  color: #4b3ad3;
  font-family: Catamaran, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 15px;
  border: none;
  background: none;
  margin: 30px auto 0;
  display: block;
}

@media only screen and (min-width: 767px) and (max-width: 1050px) {
  body .cardStyleOverView {
    width: 98%;
    min-height: 169px;
  }
  .emptyMessage {
    height: 200px;
    font-size: 14px;
  }
  .minHeight {
    min-height: 220px;
  }
  .cardMiddleStyle {
    font-weight: 800;
    font-size: 15px;
  }
  .cardHeadingStyle {
    font-weight: 800;
    font-size: 9px;
    min-width: 70px;
  }
  .cardTextStyle {
    font-size: 12px;
    max-width: 98%;
  }
  .somePadding {
    padding-left: 1px;
  }
  .teachingResourseMainDivSecond {
    width: 97%;
  }

  .teachingResourcesHeadingStyle {
    font-weight: 800;
    font-size: 17px;
  }
  .seeMoreTextStyle {
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
  }
  .studentCardStyleOverView {
    height: 420px;
    width: 100%;
  }
  .studentStyleOverView {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1051px) and (max-width: 1299px) {
  body .cardStyleOverView {
    width: 97%;
    min-height: 169px;
  }

  .cardMiddleStyle {
    font-weight: 800;
    font-size: 20px;
  }
  .cardHeadingStyle {
    font-weight: 800;
    font-size: 12px;
  }
  .cardTextStyle {
    font-size: 14px;
  }
  .somePadding {
    padding-left: 10px;
  }
  .teachingResourseMainDivSecond {
    width: 97%;
  }

  .teachingResourcesHeadingStyle {
    font-weight: 700;
    font-size: 25px;
  }
  .seeMoreTextStyle {
    font-weight: 700;
    font-size: 16px;
  }
  .studentCardStyleOverView {
    height: 500px;
    width: 100%;
  }
  .studentStyleOverView {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1700px) {
  body .cardStyleOverView {
    width: 97%;
    min-height: 182px;
  }

  .cardMiddleStyle {
    font-weight: 800;
    font-size: 30px;
  }
  .cardHeadingStyle {
    font-weight: 800;
    font-size: 18px;
  }
  .cardTextStyle {
    font-size: 16px;
  }
  .somePadding {
    padding-left: 25px;
  }
  .teachingResourseMainDivSecond {
    width: 97%;
  }

  .teachingResourcesHeadingStyle {
    font-weight: 800;
    font-size: 32px;
  }
  .seeMoreTextStyle {
    font-weight: 800;
    font-size: 18px;
  }
  .studentCardStyleOverView {
    height: 565px;
    width: 100%;
  }
  .studentStyleOverView {
    font-size: 18px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 1701px) {
  body .cardStyleOverView {
    width: 97%;
    min-height: 189px;
  }

  .cardMiddleStyle {
    font-weight: 800;
    font-size: 2rem;
  }
  .cardHeadingStyle {
    font-weight: 800;
    font-size: 20px;
  }
  .cardTextStyle {
    font-size: 18px;
  }
  .somePadding {
    padding-left: 10px;
  }
  .teachingResourseMainDivSecond {
    width: 97%;
  }

  .teachingResourcesHeadingStyle {
    font-weight: 800;
    font-size: 2rem;
  }
  .seeMoreTextStyle {
    font-weight: 800;
    font-size: 20px;
    cursor: pointer;
  }
  .studentCardStyleOverView {
    height: 630px;
    width: 100%;
  }
  .studentStyleOverView {
    font-size: 20px;
  }
}
