.school-setup__container {
  .SchoolSetupStyleHeading {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 42px;
    font-style: normal;
    color: #ffffff;
    text-align: center;
    width: 452px;
  }

  .textStyleYellow {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 16px;
    font-style: normal;
    color: #f8ca42;
    text-align: center;
    width: 585px;
  }

  .schoolSetUpLabel {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 20px;
    font-style: normal;
    color: #ffffff;
    line-height: 24px;
  }

  .inputFieldStyle {
    .ant-select-selection-item {
      font-family: Cabin, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      .school-details {
        color: #5b6a9a;
        font-family: Cabin, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}

.school-name__search {
  .ant-select-item-option {
    padding: 15px 20px;
    font-family: Cabin, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    border-bottom: 1px solid #c2ceed;

    .school-details {
      color: #5b6a9a;
      font-family: Cabin, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
