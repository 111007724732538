.teaching_resources_modal__container {
  display: flex;
  flex-direction: column;

  h3 {
    font-family: Catamaran, sans-serif;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-family: Lexend, sans-serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 0.5rem;
    }
  }
}
