.delete-account__container {
  padding: 20px 32px;

  .settingHeadingStyle {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
  }

  p {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    word-wrap: break-word;
    margin: 0;

    a {
      font-family: Catamaran, sans-serif;
      color: #4b3ad3;
      font-weight: 800;
    }
  }
}
