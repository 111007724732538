.onboarding-video__container {
  .fixCardHeadingStyle {
    text-align: center;
  }

  p {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 0;
    text-align: center;
  }

  .video__container {
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }

  button {
    width: 100%;
    text-align: center;
  }
}
