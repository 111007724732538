.card-block {
  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 30px;

  h3 {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
    color: #222222;
  }

  p {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #222222;
  }

  a {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #3a2ea6;
  }
}
