.multistep__container {
  max-width: 230px;

  .multistep__item {
    margin-bottom: 20px;
  }

  .textStyle {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #ffffff;
    text-align: left;

    &.selected {
      color: #f8ca42;
    }
    &.notSelected {
      color: #c2ceed;
    }
    &.white {
      color: #ffffff;
    }
  }

  .circleRadio {
    min-width: 13px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #ffffff;
    margin-right: 20px;
    cursor: pointer;

    &.selected {
      border: 3px solid #f8ca42;
    }

    &.notSelected {
      background-color: #c2ceed;
    }

    &.white {
      background-color: #ffffff;
    }
  }
}
