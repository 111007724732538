.mainDivSignUpStyle {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(27, 0, 136, 0.919164) -28.16%, #120b2f 87.57%);
}
.textStyle400Cabin18 {
  font-family: Cabin, sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: #ffffff;
  text-align: center;
}
.displayInline {
  display: flex;
  align-items: center;
}

.aboutTextStyle {
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  text-align: center;
  color: #ffffff;
}

.inlineFormDiv {
  display: flex;
  gap: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  body .aboutTextStyle {
    font-size: 16px;
    width: 320px;
  }
  .textStyle400Cabin18 {
    font-size: 16px;
    width: 320px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1350px) {
  body .aboutTextStyle {
    font-size: 16px;
    width: 380px;
  }
  .textStyle400Cabin18 {
    font-size: 16px;
    width: 380px;
  }
}
