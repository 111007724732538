.resources-card-v2__container {
  display: flex;
  min-height: 376px;

  .main-style {
    max-width: 281px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    cursor: pointer;
  }
  .card__thumbnail {
    max-width: 300px;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    position: relative;
    overflow: hidden;
    img {
      display: flex;
      justify-content: center;
      width: 100%;
      object-fit: fill;
      height: auto;
      border-radius: 20px 20px 0px 0px;
    }

    .card--locked {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0px 0px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 24px;

      * {
        transition: all 0.3s ease;
      }

      & > .unlock__button {
        display: flex;
        align-items: center;
        opacity: 0;
        position: absolute;
        width: auto;

        img {
          width: 19px;
          height: 19px;
          margin-left: 5px;
        }
      }

      &:hover {
        & > svg {
          opacity: 0;
        }

        & > .unlock__button {
          opacity: 1;
        }
      }
    }
  }
  .spaceBetweenResources {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    .tag-topic .backgroundColorWithText {
      max-width: 170px;
    }
  }

  .lockDivStyle {
    display: flex;
    justify-content: flex-end;
  }
  .lockImageStyle {
    width: 20px;
    height: 20px;
    color: red;
  }
  .premiumOuterDivStyle {
    display: flex;
    justify-content: flex-end;
  }
  .premiumInnerDivStyle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 20px;
  }
  .premiumImageStyle {
    width: 20px;
    height: 20px;
  }
  .premiumMainDivStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
  }

  .card__heading {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #1e163f;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 29px;

    svg {
      margin-right: 5px;
    }
  }

  .card__list-item {
    font-family: Lexend, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3b3d3c;
    margin-bottom: 5px;

    svg {
      margin-right: 5px;
    }

    button {
      background-color: transparent;
      border: none;
    }
  }
}
