.login-details {
  padding: 30px;

  .results {
    min-height: 240px;
  }

  p {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 0;
  }

  .expStyle {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #4b3ad3;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .pagination-item {
      margin: 0 5px;
      width: 20px;
      height: 20px;
      background-color: #c2ceed;
      border-radius: 50%;
      border-width: 0;
      text-indent: -9999px;

      &.current {
        color: #222222;
        background-color: #f8ca42;
      }
    }
  }
}

.show-password__modal {
  h4 {
    font-family: Catamaran, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
  }

  .clipboard-code {
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    border: 2px solid #c2ceed;
    background: #ffffff;
    padding: 19px 22px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
  }
}
