.authenticatedNavbarMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.authenticatedNavbarHeadingStyle {
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  font-size: 42px;
  font-style: normal;
  color: #222222;
}

.small-heading__container {
  display: flex;
  gap: 10px;
}

.smallHeading1Style {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #4b3ad3;
  cursor: pointer;
  line-height: 16px;
}
.smallHeading2Style {
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #8e8e8e;
  line-height: 16px;
}

.menuStyleAuthenticated {
  width: 206px;
  background: #ffffff;
  border: 1px solid #c2ceed;
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.menuItemStyleAuthenticated {
  height: 24px;
  font-family: Catamaran, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #222222;
  text-align: center;
}
.menuItemStyleAuthenticated:hover {
  color: #3a61f0;
  background: none;
}
.dividerAuthenticated {
  border: 1px solid #c2ceed;
  background: #c2ceed;
  display: flex;
  justify-content: center;
  width: 140px;
  min-width: 140px;
}
@media only screen and (min-width: 767px) and (max-width: 1050px) {
  body .authenticatedNavbarHeadingStyle {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 23px;
    font-style: normal;
    color: #222222;
  }
  .smallHeading1Style {
    font-weight: 700;
    font-size: 12px;
  }
  .smallHeading2Style {
    font-weight: 700;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1051px) and (max-width: 1280px) {
  body .authenticatedNavbarHeadingStyle {
    font-family: Catamaran, sans-serif;
    font-weight: 800;
    font-size: 30px;
    font-style: normal;
    color: #222222;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  body .authenticatedNavbarHeadingStyle {
    font-weight: 800;
    font-size: 32px;
  }
}

@media only screen and (min-width: 2101px) {
  body .authenticatedNavbarHeadingStyle {
    font-weight: 800;
    font-size: 50px;
  }
}
